import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "render-a-histogram-of-salaries"
    }}>{`Render a Histogram of salaries`}</h1>
    <Vimeo id={430409043} mdxType="Vimeo" />
    <p>{`Knowing median salaries is great and all, but it doesn't tell you much about
what you can expect. You need to know the distribution to see if it's more
likely you'll get 140k or 70k.`}</p>
    <p>{`That's what histograms are for. Give them a bunch of data, and they show its
distribution. We're going to build one like this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/basic-histogram.png",
        "alt": "Basic histogram"
      }}></img></p>
    <p>{`In the shortened dataset, 35% of tech salaries fall between \\$60k and \\$80k,
26% between \\$80k and \\$100k etc. Throwing a weighed dice with this
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Probability_distribution"
      }}>{`random distribution`}</a>{`,
you're far more likely to get 60k-80k than 120k-140k. It's a great way to gauge
situations.`}</p>
    <p>{`It's where statistics like "More people die from vending machines than shark
attacks" come from. Which are you afraid of, vending machines or sharks? Stats
say your answer should be
`}<a parentName="p" {...{
        "href": "https://www.cdc.gov/nchs/fastats/deaths.htm"
      }}>{`heart disease`}</a>{`. 😉`}</p>
    <p>{`We'll start our histogram with some changes in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`, make a `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{`
component using the
`}<a parentName="p" {...{
        "href": "/building-blocks/3"
      }}>{`full-feature approach`}</a>{`,
add an `}<inlineCode parentName="p">{`Axis`}</inlineCode>{` using the
`}<a parentName="p" {...{
        "href": "/building-blocks/5"
      }}>{`blackbox useD3 approach`}</a>{`,
and finally add some styling.`}</p>
    <h2 {...{
      "id": "step-1-prep-appjs"
    }}>{`Step 1: Prep App.js`}</h2>
    <Vimeo id={430409258} mdxType="Vimeo" />
    <p>{`You know the drill, don't you? Import some stuff, add it to the `}<inlineCode parentName="p">{`render()`}</inlineCode>{`
method in the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
import _ from "lodash"

// Insert the line(s) between here...
import "./style.css"
// ...and here.

import Preloader from "./components/Preloader"
import { loadAllData } from "./DataHandling"

import CountyMap from "./components/CountyMap"
// Insert the line(s) between here...
import Histogram from "./components/Histogram"
// ...and here.
`}</code></pre>
    <p>{`We import `}<inlineCode parentName="p">{`style.css`}</inlineCode>{` and the `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` component. That's what I love about
Webpack - you can import CSS in JavaScript. We got the setup with
`}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`.`}</p>
    <p>{`There are competing schools of thought about styling React apps. Some say each
component should come with its own CSS files, some think it should be in large
per-app CSS files, many think CSS-in-JS is the way to go.`}</p>
    <p>{`Personally I like to use CSS for general cross-component styling and
styled-components for more specific styles. We're using CSS in this project
because it works and means we don't have to learn yet another dependency.`}</p>
    <p>{`After the imports, we can render our `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` in the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
// ...
render() {
    // ...
    return (
        <div className="App container">
            <h1>Loaded {this.state.techSalaries.length} salaries</h1>
            <svg width="1100" height="500">
                <CountyMap usTopoJson={this.state.usTopoJson}
                           USstateNames={this.state.USstateNames}
                           values={countyValues}
                           x={0}
                           y={0}
                           width={500}
                           height={500}
                           zoom={zoom} />
                // Insert the line(s) between here...
                <Histogram bins={10}
                           width={500}
                           height={500}
                           x="500"
                           y="10"
                           data={filteredSalaries}
                           axisMargin={83}
                           bottomMargin={5}
                           value={d => d.base_salary} />
                // ...and here.
            </svg>
        </div>
    );
}
`}</code></pre>
    <p>{`We render the `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` component with a bunch of props. They specify the
dimensions we want, positioning, and pass data to the component. We're using
`}<inlineCode parentName="p">{`filteredSalaries`}</inlineCode>{` even though we haven't set up any filtering yet. One less
line of code to change later 👌`}</p>
    <p>{`That's it. `}<inlineCode parentName="p">{`App`}</inlineCode>{` is ready to render our `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{`.`}</p>
    <p>{`You should now see an error about missing files. That's normal.`}</p>
    <h2 {...{
      "id": "step-2-css-changes"
    }}>{`Step 2: CSS changes`}</h2>
    <Vimeo id={430409423} mdxType="Vimeo" />
    <p>{`As mentioned, opinions vary on the best approach to styling React apps. Some
say stylesheets per component, some say styling inside JavaScript, others swear
by global app styling.`}</p>
    <p>{`The truth is somewhere in between. Do what fits your project and your team.
We're using global stylesheets because it's the simplest.`}</p>
    <p>{`Create a new file `}<inlineCode parentName="p">{`src/style.css`}</inlineCode>{` and add these 29 lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.histogram .bar rect {
  fill: steelblue;
  shape-rendering: crispEdges;
}

.histogram .bar text {
  fill: #fff;
  font: 12px sans-serif;
}

button {
  margin-right: 0.5em;
  margin-bottom: 0.3em !important;
}

.row {
  margin-top: 1em;
}

.mean text {
  font: 11px sans-serif;
  fill: grey;
}

.mean path {
  stroke-dasharray: 3;
  stroke: grey;
  stroke-width: 1px;
}
`}</code></pre>
    <p>{`We won't go into details about the CSS here. Many better books have been
written about it.`}</p>
    <p>{`In broad strokes:`}</p>
    <ul>
      <li parentName="ul">{`we're making `}<inlineCode parentName="li">{`.histogram`}</inlineCode>{` rectangles – the bars – blue`}</li>
      <li parentName="ul">{`labels white `}<inlineCode parentName="li">{`12px`}</inlineCode>{` font`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`button`}</inlineCode>{`s and `}<inlineCode parentName="li">{`.row`}</inlineCode>{`s have some spacing`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`.mean`}</inlineCode>{` line is a dotted grey with gray `}<inlineCode parentName="li">{`11px`}</inlineCode>{` text.`}</li>
    </ul>
    <p>{`More CSS than we need for just the histogram, but we're already here so might
as well write it now.`}</p>
    <p>{`Adding our CSS before building the Histogram means it's going to look beautiful
the first time around.`}</p>
    <h2 {...{
      "id": "step-3-histogram-component"
    }}>{`Step 3: Histogram component`}</h2>
    <Vimeo id={430409709} mdxType="Vimeo" />
    <p>{`We're following the
`}<a parentName="p" {...{
        "href": "/building-blocks/3"
      }}>{`full-feature integration`}</a>{`
approach for our Histogram component. React talks to the DOM, D3 calculates the
props.`}</p>
    <p>{`We'll use two components:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`Histogram`}</inlineCode>{` makes the general layout, dealing with D3, and translating raw
data into a histogram`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HistogramBar`}</inlineCode>{` draws a single bar and labels it`}</li>
    </ol>
    <p>{`We create the `}<inlineCode parentName="p">{`Histogram.js`}</inlineCode>{` file. Start with some imports, a default export,
and a stubbed out `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Histogram.js
import React from "react"
import * as d3 from "d3"

const Histogram = ({
  bins,
  width,
  height,
  x,
  y,
  data,
  axisMargin,
  bottomMargin,
  value,
}) => {
  const histogram = d3.histogram()
  const widthScale = d3.scaleLinear()
  const yScale = d3.scaleLinear()

  return null
}
`}</code></pre>
    <p>{`We import React and D3, and set up `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` with 3 D3 elements`}</p>
    <ol>
      <li parentName="ol">{`a histogram generator`}</li>
      <li parentName="ol">{`a linear width scale`}</li>
      <li parentName="ol">{`a linear y scale`}</li>
    </ol>
    <h3 {...{
      "id": "rendering-the-histogram"
    }}>{`Rendering the histogram`}</h3>
    <Vimeo id={430410657} mdxType="Vimeo" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Histogram.js
const histogram = d3.histogram().thresholds(bins).value(value)

const bars = histogram(data),
  counts = bars.map((d) => d.length)

const widthScale = d3
  .scaleLinear()
  .domain([d3.min(counts), d3.max(counts)])
  .range([0, width - axisMargin])

const yScale = d3
  .scaleLinear()
  .domain([0, d3.max(bars, (d) => d.x1)])
  .range([height - y - bottomMargin, 0])
`}</code></pre>
    <p>{`First, we configure the `}<inlineCode parentName="p">{`histogram`}</inlineCode>{` generator. `}<inlineCode parentName="p">{`thresholds`}</inlineCode>{` specify how many
bins we want and `}<inlineCode parentName="p">{`value`}</inlineCode>{` specifies the value accessor function. We get both
from props passed into the `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` component.`}</p>
    <p>{`In our case that makes 20 bins, and the value accessor returns each data
point's `}<inlineCode parentName="p">{`base_salary`}</inlineCode>{`.`}</p>
    <p>{`We feed the data prop into our histogram generator, and count how many values
are in each bin with a `}<inlineCode parentName="p">{`.map`}</inlineCode>{` call. We need those to configure our scales.`}</p>
    <p>{`If you print the result of `}<inlineCode parentName="p">{`histogram()`}</inlineCode>{`, you'll see an array structure where
each entry holds metadata about the bin and the values it contains.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/histogram-data-screenshot.png",
        "alt": "console.log(this.histogram())"
      }}></img></p>
    <p>{`Let's use this info to set up our scales.`}</p>
    <p><inlineCode parentName="p">{`widthScale`}</inlineCode>{` has a range from the smallest (`}<inlineCode parentName="p">{`d3.min`}</inlineCode>{`) bin to the largest
(`}<inlineCode parentName="p">{`d3.max`}</inlineCode>{`), and a range of `}<inlineCode parentName="p">{`0`}</inlineCode>{` to width less a margin. We'll use it to
calculate bar sizes.`}</p>
    <p><inlineCode parentName="p">{`yScale`}</inlineCode>{` has a range from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to the largest `}<inlineCode parentName="p">{`x1`}</inlineCode>{` coordinate we can find in a
bin. Bins go from `}<inlineCode parentName="p">{`x0`}</inlineCode>{` to `}<inlineCode parentName="p">{`x1`}</inlineCode>{`, which reflects the fact that most histograms
are horizontally oriented. Ours is vertical so that our labels are easier to
read. The range goes from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to the maximum height less a margin.`}</p>
    <p>{`Now let's render this puppy.`}</p>
    <h3 {...{
      "id": "render"
    }}>{`render`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Histogram.js
<g className="bars">
  {bars.map((bar) => (
    <HistogramBar
      percent={(bar.length / data.length) * 100}
      x={axisMargin}
      y={yScale(bar.x1)}
      width={widthScale(bar.length)}
      height={yScale(bar.x0) - yScale(bar.x1)}
      key={\`histogram-bar-\${bar.x0}\`}
    />
  ))}
</g>
`}</code></pre>
    <p>{`We take everything we need out of `}<inlineCode parentName="p">{`state`}</inlineCode>{` and `}<inlineCode parentName="p">{`props`}</inlineCode>{` with destructuring, call
`}<inlineCode parentName="p">{`histogram()`}</inlineCode>{` on our data to get a list of bars, and render.`}</p>
    <p>{`Our render method returns a `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` grouping element transformed to the position
given in props and walks through the `}<inlineCode parentName="p">{`bars`}</inlineCode>{` array, calling `}<inlineCode parentName="p">{`makeBar`}</inlineCode>{` for each.
Later, we're going to add an `}<inlineCode parentName="p">{`Axis`}</inlineCode>{` as well.`}</p>
    <p>{`This is a great example of React's declarativeness. We have a bunch of stuff,
and all it takes to render is a loop. No worrying about how it renders, where
it goes, or anything like that. Walk through data, render, done.`}</p>
    <p>{`Setting the `}<inlineCode parentName="p">{`key`}</inlineCode>{` prop is important. React uses it to tell the bars apart and
only re-render those that change.`}</p>
    <h2 {...{
      "id": "step-4-histogrambar-subcomponent"
    }}>{`Step 4: HistogramBar (sub)component`}</h2>
    <Vimeo id={430410847} mdxType="Vimeo" />
    <p>{`Before our histogram shows up, we need another component: `}<inlineCode parentName="p">{`HistogramBar`}</inlineCode>{`. We
`}<em parentName="p">{`could`}</em>{` have shoved all of it in the `}<inlineCode parentName="p">{`makeBar`}</inlineCode>{` function, but it makes sense to
keep separate. Better future flexibility.`}</p>
    <p>{`You can write small components like this in the same file as their main
component. They're not reusable since they fit a specific use-case, and they're
small enough so your files don't get too crazy.`}</p>
    <p>{`But in the interest of readability, let's make a `}<inlineCode parentName="p">{`HistogramBar`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Histogram.js

const HistogramBar = ({ percent, x, y, width, height }) => {
  let translate = \`translate(\${x}, \${y})\`,
    label = percent.toFixed(0) + "%"

  if (percent < 1) {
    label = percent.toFixed(2) + "%"
  }

  if (width < 20) {
    label = label.replace("%", "")
  }

  if (width < 10) {
    label = ""
  }

  return (
    <g transform={translate} className="bar">
      <rect width={width} height={height - 2} transform="translate(0, 1)" />
      <text textAnchor="end" x={width - 5} y={height / 2 + 3}>
        {label}
      </text>
    </g>
  )
}
`}</code></pre>
    <p>{`We start by deciding how much precision to put in the label. Makes the smaller bars easier to read :)`}</p>
    <p>{`Then we render a `}<inlineCode parentName="p">{`rect`}</inlineCode>{`angle for the bar and add a `}<inlineCode parentName="p">{`text`}</inlineCode>{` element for the label. Positioning based on size.`}</p>
    <p>{`You should now see a histogram.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/histogram-without-axis.png",
        "alt": "Histogram without axis"
      }}></img></p>
    <h2 {...{
      "id": "step-5-axis"
    }}>{`Step 5: Axis`}</h2>
    <Vimeo id={430411631} mdxType="Vimeo" />
    <p>{`Our histogram is pretty, but it needs an axis to be useful. You've already
learned how to implement an axis when we talked about
`}<a parentName="p" {...{
        "href": "/building-blocks/1"
      }}>{`blackbox integration`}</a>{`.
We're going to use the same approach and copy those concepts into the real
project.`}</p>
    <h3 {...{
      "id": "axis-component"
    }}>{`Axis component`}</h3>
    <p>{`We can use the `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` hook from my `}<inlineCode parentName="p">{`d3blackbox`}</inlineCode>{` library to make this work quickly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Axis.js

import React from "react"
import { useD3 } from "d3blackbox"

import * as d3 from "d3"

const Axis = ({ x, y, scale, type = "Bottom" }) => {
  const gRef = useD3((anchor) => {
    const axis = d3[\`axis\${type}\`](scale)

    d3.select(anchor).call(axis)
  })

  return <g transform={\`translate(\${x}, \${y})\`} ref={gRef} />
}

export default Axis
`}</code></pre>
    <p>{`We use D3's axis generator based on the `}<inlineCode parentName="p">{`type`}</inlineCode>{` prop and pass in a `}<inlineCode parentName="p">{`scale`}</inlineCode>{`. To render, we `}<inlineCode parentName="p">{`select`}</inlineCode>{` the anchor element and `}<inlineCode parentName="p">{`call`}</inlineCode>{` the axis generator on it.`}</p>
    <h3 {...{
      "id": "add-axis-to-histogram"
    }}>{`Add Axis to Histogram`}</h3>
    <p>{`To render our new `}<inlineCode parentName="p">{`Axis`}</inlineCode>{`, we add it to the `}<inlineCode parentName="p">{`Histogram`}</inlineCode>{` component. It's a two
step process:`}</p>
    <ol>
      <li parentName="ol">{`Import `}<inlineCode parentName="li">{`Axis`}</inlineCode>{` component`}</li>
      <li parentName="ol">{`Render it`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Histogram/Histogram.js
import React, { Component } from "react"
import * as d3 from "d3"

// Insert the line(s) between here...
import Axis from "./Axis"
// ...and here.

// ...
const Histogram = () => {
  // ...

  return (
    <g className="histogram" transform={translate}>
      <g className="bars">{bars.map(this.makeBar)}</g>
      // Insert the line(s) between here...
      <Axis x={axisMargin - 3} y={0} data={bars} scale={yScale} />
      // ...and here.
    </g>
  )
}
`}</code></pre>
    <p>{`We import our `}<inlineCode parentName="p">{`Axis`}</inlineCode>{` and add it to the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method with some props. It
takes an `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinate, the `}<inlineCode parentName="p">{`data`}</inlineCode>{`, and a `}<inlineCode parentName="p">{`scale`}</inlineCode>{`.`}</p>
    <p>{`An axis appears.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/basic-histogram.png",
        "alt": "Basic histogram with axis"
      }}></img></p>
    <p>{`If that didn't work, try comparing your changes to this
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/0475adae7b311f41804cdc385134dd638fbbbf27"
      }}>{`diff on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      